//@author: mandy

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    NgZone,
    OnInit,
} from "@angular/core";
import { S25EditableAbstract } from "../s25.editable.abstract";
import { S25Util } from "../../../util/s25-util";
import { TypeManagerDecorator } from "../../../main/type.map.service";

@TypeManagerDecorator("s25-ng-editable-duration")
@Component({
    selector: "s25-ng-editable-duration",
    template: `
        @if (readOnly) {
            <span>{{ val | durationPipe }}</span>
        }

        @if (!readOnly) {
            <div>
                @if (!editing) {
                    <div
                        class="s25-editable-shared-container c-editable-text editable editable-click"
                        (click)="editMode()"
                        (keyup.enter)="editMode()"
                        (keyup.space)="editMode()"
                    >
                        <div class="s25-editable s25-data">{{ val | durationPipe }}</div>
                    </div>
                }
                @if (editing) {
                    <div>
                        <div>
                            <label for="days" class="timeLabel">
                                <span class="c-margin-right--half c-margin-bottom--quarter">Days </span>
                                <input
                                    type="number"
                                    step="1"
                                    min="0"
                                    max="65536"
                                    id="days"
                                    name="days"
                                    [(ngModel)]="candidateVal.days"
                                    (ngModelChange)="update($event, false, 'days')"
                                    (blur)="onInputBlur()"
                                    (keyup.enter)="submit()"
                                    class="firstFocusEditable c-input"
                                />
                            </label>
                        </div>
                        <div>
                            <label for="hours" class="timeLabel">
                                <span class="c-margin-right--half c-margin-bottom--quarter">Hours </span>
                                <input
                                    type="number"
                                    step="1"
                                    min="0"
                                    max="23"
                                    id="hours"
                                    name="hours"
                                    [ngModel]="candidateVal.hours"
                                    (ngModelChange)="update($event, false, 'hours')"
                                    (blur)="onInputBlur()"
                                    (keyup.enter)="submit()"
                                    class="firstFocusEditable c-input"
                                />
                            </label>
                        </div>
                        <div>
                            <label for="minutes" class="timeLabel">
                                <span class="c-margin-right--half c-margin-bottom--quarter">Minutes </span>
                                <input
                                    type="number"
                                    step="1"
                                    min="0"
                                    max="59"
                                    id="minutes"
                                    name="minutes"
                                    [ngModel]="candidateVal.minutes"
                                    (ngModelChange)="update($event, false, 'minutes')"
                                    (blur)="onInputBlur()"
                                    (keyup.enter)="submit()"
                                    class="firstFocusEditable c-input"
                                />
                            </label>
                        </div>
                        <div class="aw-button-group">
                            @if (hasCancelButton) {
                                <button
                                    class="aw-button aw-button--outline"
                                    (click)="cancel()"
                                    (keyup.enter)="cancel()"
                                >
                                    {{ cancelButtonText }}
                                </button>
                            }
                            @if (hasCommitButton) {
                                <button
                                    class="aw-button aw-button--primary"
                                    (click)="commit()"
                                    (keyup.enter)="commit()"
                                >
                                    {{ commitButtonText }}
                                </button>
                            }
                        </div>
                        @if (errorMessages) {
                            <div>
                                @for (msg of errorMessages; track msg) {
                                    <div class="ngRed ngBold">
                                        {{ msg }}
                                    </div>
                                }
                            </div>
                        }
                    </div>
                }
            </div>
        }
    `,
    styles: `
        .timeLabel {
            display: flex;
        }

        .timeLabel > span {
            min-width: 4em;
        }

        .timeLabel > input {
            flex-grow: 1;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25EditableDurationComponent extends S25EditableAbstract implements OnInit {
    getType = () => "duration";
    @Input() val: string; //P0DT00H00M ISODuration
    @Input() onUpdate: (inputVal: any) => any = (inputVal: any) => {
        if (inputVal.days === null || !Number.isInteger(inputVal.days)) {
            this.candidateVal.days = 0;
        }
        if (inputVal.hours > 23 || inputVal.hours === null || !Number.isInteger(inputVal.hours)) {
            this.candidateVal.hours = 23;
        }
        if (inputVal.minutes > 59 || inputVal.minutes === null || !Number.isInteger(inputVal.minutes)) {
            this.candidateVal.minutes = 59;
        }
        // this.val = S25Util.daysHoursMinutesToDuration(inputVal);
        this.cd.detectChanges();
    }; //if NOT hasCommit, this runs on each model val update

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
        private zone: NgZone,
    ) {
        super(elementRef, cd, zone);
    }

    ngOnInit() {
        this.candidateVal = S25Util.ISODurationToObj(this.val);
        super.ngOnInit();
    }
}
