//@author: travis
import { NgModule } from "@angular/core";
import { S25EditablePercentageComponent } from "./s25-editable-percentage/s25.editable.percentage.component";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { S25EditableNumberComponent } from "./s25-editable-number/s25.editable.number.component";
import { S25EditableTextComponent } from "./s25-editable-text/s25.editable.text.component";
import { S25EditableTextareaComponent } from "./s25-editable-textarea/s25.editable.textarea.component";
import { S25EditableRichtextComponent } from "./s25-editable-richtext/s25.editable.richtext.component";
import { S25RichTextEditorModule } from "../s25-rich-text-editor/s25.rich.text.editor.module";
import { S25EditableDateComponent } from "./s25-editable-date/s25.editable.date.component";
import { S25DatepickerModule } from "../s25-datepicker/s25.datepicker.module";
import { S25DateModule } from "../s25-dateformat/s25.date.module";
import { S25EditableDurationComponent } from "./s25-editable-duration/s25.editable.duration.component";
import { S25PipesModule } from "../../pipes/s25.pipes.module";
import { S25EditableStartEndDateTimeComponent } from "./s25-editable-start-end-datetime/s25.editable.start.end.date.time.component";
import { S25TimepickerModule } from "../s25-timepicker/s25.timepicker.module";
import { S25CheckboxModule } from "../s25-checkbox/s25.checkbox.module";
import { S25EditableImageComponent } from "./s25-editable-image/s25.editable.image.component";
import { S25ImageModule } from "../s25-image/s25.image.module";
import { S25ImageUploadModule } from "../s25-image-upload/s25.image.upload.module";
import { S25EditableDateTimeComponent } from "./s25-editable-date-time/s25.editable.date.time.component";
import { PopoverModule } from "../s25-popover/popover.module";
import { S25EditableAbstract } from "./s25.editable.abstract";
import { S25EditableCustomAttributeComponent } from "./s25.editable-custom-attribute/s25.editable-custom-attribute.component";
import { S25DropdownPaginatedModule } from "../s25-dropdown/s25.dropdown.paginated.module";
import { S25ToggleButtonModule } from "../s25-toggle-button/s25.toggle.button.module";
import { S25LoadingInlineModule } from "../s25-loading/s25.loading.inline.module";

@NgModule({
    declarations: [
        S25EditableAbstract,
        S25EditablePercentageComponent,
        S25EditableNumberComponent,
        S25EditableTextComponent,
        S25EditableTextareaComponent,
        S25EditableRichtextComponent,
        S25EditableDateComponent,
        S25EditableDurationComponent,
        S25EditableStartEndDateTimeComponent,
        S25EditableImageComponent,
        S25EditableDateTimeComponent,
        S25EditableCustomAttributeComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        S25RichTextEditorModule,
        S25DatepickerModule,
        S25DateModule,
        S25PipesModule,
        S25TimepickerModule,
        S25CheckboxModule,
        S25ImageModule,
        S25ImageUploadModule,
        PopoverModule,
        S25DropdownPaginatedModule,
        S25ToggleButtonModule,
        S25LoadingInlineModule,
    ],
    providers: [
        S25EditableAbstract,
        S25EditablePercentageComponent,
        S25EditableNumberComponent,
        S25EditableTextComponent,
        S25EditableTextareaComponent,
        S25EditableRichtextComponent,
        S25EditableDateComponent,
        S25EditableDurationComponent,
        S25EditableStartEndDateTimeComponent,
        S25EditableImageComponent,
        S25EditableDateTimeComponent,
        S25EditableCustomAttributeComponent,
    ],
    exports: [
        S25EditablePercentageComponent,
        S25EditableNumberComponent,
        S25EditableTextComponent,
        S25EditableTextareaComponent,
        S25EditableRichtextComponent,
        S25EditableDateComponent,
        S25EditableDurationComponent,
        S25EditableStartEndDateTimeComponent,
        S25EditableImageComponent,
        S25EditableDateTimeComponent,
        S25EditableCustomAttributeComponent,
    ],
})
export class S25EditableModule {}
