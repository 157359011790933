//@author: devin

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    NgZone,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { S25EditableAbstract } from "../s25.editable.abstract";
import { TypeManagerDecorator } from "../../../main/type.map.service";

@TypeManagerDecorator("s25-ng-editable-number")
@Component({
    selector: "s25-ng-editable-number",
    template: `
        @if (this.readOnly) {
            <span>{{ this.val }}</span>
        }

        @if (!this.readOnly) {
            <div>
                @if (!this.editing) {
                    <div
                        class="s25-editable-shared-container c-editable-text editable editable-click"
                        tabindex="0"
                        (click)="this.editMode()"
                        (keyup.enter)="this.editMode()"
                        (keyup.space)="this.editMode()"
                        [attr.aria-label]="ariaLabel || 'Editable Number'"
                    >
                        <div class="s25-editable s25-data">{{ this.val }}</div>
                    </div>
                }
                @if (this.editing) {
                    <div>
                        <input
                            class="firstFocusEditable c-input"
                            type="number"
                            [min]="this.min"
                            [max]="this.max"
                            [disabled]="this.disabled"
                            step="1"
                            [ngModel]="this.candidateVal"
                            (ngModelChange)="this.update($event)"
                            (blur)="this.onInputBlur()"
                            (keyup.enter)="this.submit()"
                        />
                        <div class="aw-button-group">
                            @if (this.hasCancelButton) {
                                <button
                                    class="aw-button aw-button--outline"
                                    (click)="this.cancel()"
                                    (keyup.enter)="this.cancel()"
                                >
                                    {{ this.cancelButtonText }}
                                </button>
                            }
                            @if (this.hasCommitButton) {
                                <button
                                    class="aw-button aw-button--primary"
                                    (click)="this.commit()"
                                    (keyup.enter)="this.commit()"
                                >
                                    {{ this.commitButtonText }}
                                </button>
                            }
                        </div>
                        @if (this.errorMessages) {
                            <div>
                                @for (msg of this.errorMessages; track msg) {
                                    <div class="ngRed ngBold">
                                        {{ msg }}
                                    </div>
                                }
                            </div>
                        }
                    </div>
                }
            </div>
        }
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25EditableNumberComponent extends S25EditableAbstract implements OnInit {
    @Input() type: "integer" | "float" = "integer";

    getType = () => {
        return this.type;
    };

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
        private zone: NgZone,
    ) {
        super(elementRef, cd, zone);
    }

    ngOnInit() {
        super.ngOnInit();
    }
}
