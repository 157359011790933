import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnInit,
    Output,
    ViewEncapsulation,
} from "@angular/core";
import { S25EditableAbstract } from "../s25-editable/s25.editable.abstract";
import { S25ItemI } from "../../pojo/S25ItemI";
import { SearchCriteriaType } from "../../pojo/SearchCriteriaI";
import { OrganizationService } from "../../services/organization.service";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { SpaceService } from "../../services/space.service";
import { ResourceService } from "../../services/resource.service";
import { MultiselectModelI } from "../s25-multiselect/s25.multiselect.component";

export interface EditablePropertyModelI extends MultiselectModelI {
    canEdit?: boolean;
    // ID of the specific object we are interacting with
    itemId: number;
    // ID of obj type; org/space/resource
    itemTypeId: 2 | 4 | 6;
    // array of properties fed into multiselect
    properties?: S25ItemI[];
    // Property type used for search criteria in multiselect
    propType: SearchCriteriaType["type"];
    // Used to dynamically build payload
    propPayloadType: "category";
    noneText: string;
}

@TypeManagerDecorator("s25-ng-editable-property")
@Component({
    selector: "s25-ng-editable-property",
    template: `
        @if (init) {
            <div>
                <ng-content></ng-content>
                @for (property of this.model.properties; track property) {
                    <div style="display: flex;flex-direction: row;align-items: center;justify-content: space-between;">
                        <span class="ng-binding">{{ property.itemName }}</span>
                        @if (this.model.canEdit) {
                            <div (click)="this.model.removeAction(property)" style="cursor: pointer;" tabindex="0">
                                <svg class="c-svgIcon">
                                    <title>Delete</title>
                                    <use
                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                        xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#close-x"
                                    ></use>
                                </svg>
                            </div>
                        }
                    </div>
                }
                @if (!this.model.canEdit && !this.properties.length) {
                    <div>
                        {{ this.model.noneText }}
                    </div>
                }
                @if (this.model.canEdit) {
                    <div>
                        <s25-ng-multiselect-search-criteria
                            [type]="this.model.propType"
                            [popoverOnBody]="true"
                            [modelBean]="this.model"
                            [honorMatching]="true"
                            [useSecurity]="true"
                            [selectedItems]="this.properties"
                            [customPopoverClass]="'additional-org-popover'"
                        ></s25-ng-multiselect-search-criteria>
                    </div>
                }
            </div>
        }
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25EditablePropertyComponent extends S25EditableAbstract implements OnInit {
    @Input() model: EditablePropertyModelI;
    @Output() changed: EventEmitter<any> = new EventEmitter();

    getType = () => this.model.propPayloadType;

    propNameKey: string;
    propIdKey: string;
    microPropIdKey: string;
    microPropWrapperKey: string;

    // service to be called
    objectService: (itemId: number, payload: any) => Promise<any>;
    microService: (payload: any) => Promise<any> = undefined;

    init: boolean = false;
    properties: S25ItemI[] = [];

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
        private zone: NgZone,
    ) {
        super(elementRef, cd, zone);
        this.elementRef.nativeElement.angBridge = this; //bridge to AngularJS; used for AngJS to set model values and call setter fns
    }

    ngOnInit() {
        super.ngOnInit();

        // Switch service based on object type
        switch (this.model.itemTypeId) {
            case 2:
                this.objectService = OrganizationService.putOrganization;
                break;
            case 4:
                this.microService = SpaceService.microUpdate;
                this.objectService = SpaceService.putSpace;
                break;
            case 6:
                this.objectService = ResourceService.putResource;
                break;
        }

        // Switch payload keys based on property type
        switch (this.model.propPayloadType) {
            case "category":
                this.propNameKey = "category_name";
                this.propIdKey = "category_id";
                this.microPropIdKey = "categoryId";
                this.microPropWrapperKey = "categories";
                break;
        }

        this.properties = this.normalize(this.model.properties);

        this.model.addAction = (item: S25ItemI) => {
            this.model.addedItems = [];
            this.editProperty(item, false).then(() => {
                return this.cd.detectChanges();
            });
        };

        this.model.removeAction = (item: S25ItemI) => {
            this.model.removedItems = [];
            this.editProperty(item, true).then(() => {
                return this.cd.detectChanges();
            });
        };

        this.model.onDone = () => {
            if (this.model.addedItems.length) {
                this.editProperties(this.model.addedItems, false).then(this.cd.detectChanges);
                this.model.addedItems = [];
            }
            if (this.model.removedItems.length) {
                this.editProperties(this.model.removedItems, true).then(this.cd.detectChanges);
                this.model.removedItems = [];
            }
        };

        this.init = true;
        this.cd.detectChanges();
    }

    normalize(arr: S25ItemI[]): S25ItemI[] {
        return arr.map((item) => {
            item.itemId = item.itemId || item[this.propIdKey];
            item.itemName = item.itemName || item[this.propNameKey];
            return item;
        });
    }

    editProperty(prop: S25ItemI, remove: boolean) {
        prop[this.propNameKey] = prop.itemName;
        prop[this.propIdKey] = prop.itemId;
        prop.checked = !remove;
        remove ? (prop.status = "del") : (prop.status = "new");

        // If we have defined a microService, we will use that instead.
        return this.microService
            ? this.microService({
                  id: this.model.itemId,
                  [this.microPropWrapperKey]: [{ [this.microPropIdKey]: prop.itemId }],
              }).then(() => {
                  this.changed.emit(this.model.properties);
              })
            : this.objectService(this.model.itemId, { [this.model.propPayloadType]: prop }).then(() => {
                  remove
                      ? S25Util.array.inplaceRemoveByProp(this.model.properties, "itemId", prop.itemId)
                      : S25Util.array.inplacePushByProp(this.model.properties, "itemId", prop.itemId, prop);
                  this.changed.emit(this.model.properties);
              });
    }

    editProperties(props: S25ItemI[], remove: boolean) {
        let newProps: S25ItemI[] = [];

        props.map((prop) => {
            prop[this.propNameKey] = prop.itemName;
            prop[this.propIdKey] = prop.itemId;
            prop.checked = !remove;
            remove ? (prop.status = "del") : (prop.status = "new");
            return newProps.push(prop);
        });

        return this.objectService(this.model.itemId, { category: newProps }).then(() => {
            remove
                ? props.forEach((prop) => {
                      S25Util.array.inplaceRemoveByProp(this.model.properties, "itemId", prop.itemId);
                  })
                : props.forEach((prop) => {
                      S25Util.array.inplacePushByProp(this.model.properties, "itemId", prop.itemId, prop);
                  });
            this.changed.emit(this.model.properties);
        });
    }
}
