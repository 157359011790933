//@author: devin

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    NgZone,
    OnChanges,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { S25EditableAbstract } from "../s25.editable.abstract";
import { TypeManagerDecorator } from "../../../main/type.map.service";

@TypeManagerDecorator("s25-ng-editable-text")
@Component({
    selector: "s25-ng-editable-text",
    template: `
        @if (this.readOnly) {
            <span>{{ this.val }}</span>
        }

        @if (!this.readOnly) {
            <div>
                @if (!this.editing) {
                    <div
                        class="s25-editable-shared-container c-editable-text editable editable-click"
                        (click)="this.editMode()"
                        (keyup.enter)="this.editMode()"
                        (keyup.space)="this.editMode()"
                        tabindex="0"
                    >
                        <div class="s25-editable s25-data">{{ this.val }}</div>
                    </div>
                }
                @if (this.editing) {
                    <div>
                        <input
                            class="firstFocusEditable c-input"
                            type="text"
                            [id]="this.fieldID"
                            [minlength]="this.min"
                            [maxlength]="this.max"
                            [disabled]="this.disabled"
                            [ngModel]="this.candidateVal"
                            (ngModelChange)="this.update($event)"
                            (blur)="this.onInputBlur()"
                            (keyup.enter)="this.submit()"
                        />
                        <div class="aw-button-group">
                            @if (this.hasCancelButton) {
                                <button
                                    class="aw-button aw-button--outline"
                                    (click)="this.cancel()"
                                    (keyup.enter)="this.cancel()"
                                >
                                    {{ this.cancelButtonText }}
                                </button>
                            }
                            @if (this.hasCommitButton) {
                                <button
                                    class="aw-button aw-button--primary"
                                    (click)="this.commit()"
                                    (keyup.enter)="this.commit()"
                                    [disabled]="committing"
                                    [class.is-committing]="committing"
                                >
                                    {{ committing ? "" : commitButtonText }}
                                    <s25-loading-inline [model]="{}"></s25-loading-inline>
                                </button>
                            }
                        </div>
                        @if (this.errorMessages) {
                            <div>
                                @for (msg of this.errorMessages; track msg) {
                                    <div class="ngRed ngBold">
                                        {{ msg }}
                                    </div>
                                }
                            </div>
                        }
                    </div>
                }
            </div>
        }
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25EditableTextComponent extends S25EditableAbstract implements OnInit, OnChanges {
    getType = () => "text";

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
        private zone: NgZone,
    ) {
        super(elementRef, cd, zone);
    }

    ngOnInit() {
        super.ngOnInit();
    }

    ngOnChanges() {
        this.valChange.emit(this.val);
    }
}
