//@author: devin

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    NgZone,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewEncapsulation,
} from "@angular/core";
import { S25EditableAbstract } from "../s25.editable.abstract";
import { UserprefService } from "../../../services/userpref.service";
import { jSith } from "../../../util/jquery-replacement";
import { S25Util } from "../../../util/s25-util";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { DatepickerApi } from "../../s25-datepicker/date.picker.api";

@TypeManagerDecorator("s25-ng-editable-date")
@Component({
    selector: "s25-ng-editable-date",
    template: `
        @if (this.init) {
            <div>
                @if (this.readOnly) {
                    <span>{{ this.val | datePipe: this.dateFormat }}</span>
                }
                @if (!this.readOnly) {
                    <div>
                        <!--<div *ngIf="!this.editing" class="s25-editable-shared-container c-editable-text editable editable-click"
                (click)="this.editDate()"
                (keyup.enter)="this.editDate()"
                (keyup.space)="this.editDate()" tabindex="0">
                <div class="s25-editable s25-data">{{this.val | datePipe:this.dateFormat}}</div>
              </div>
              class="s25-editable-shared-container c-editable-text editable editable-click"
              -->
                        <div class="editable">
                            <div class="editable-dp">
                                <s25-datepicker
                                    [modelValue]="this.modelValue"
                                    (modelValueChange)="dateSubmit($event)"
                                    [onClose]="this.close"
                                    [minDate]="minDate"
                                    [maxDate]="maxDate"
                                    [inputClass]="inputClass"
                                    [popoverOnBody]="popoverOnBody"
                                    [inputId]="inputId"
                                    [allowEmpty]="allowEmpty"
                                    [placeholder]="placeholder"
                                ></s25-datepicker>
                                @if (!this.editing && hasIcon) {
                                    <svg class="c-svgIcon edit-icon">
                                        <use
                                            xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#edit"
                                        />
                                    </svg>
                                }
                            </div>
                            @if (this.hasCancelButton || this.hasCommitButton) {
                                <div class="aw-button-group">
                                    @if (this.hasCancelButton) {
                                        <button
                                            class="aw-button aw-button--outline"
                                            (click)="this.cancel()"
                                            (keyup.enter)="this.cancel()"
                                        >
                                            {{ this.cancelButtonText }}
                                        </button>
                                    }
                                    @if (this.hasCommitButton) {
                                        <button
                                            class="aw-button aw-button--primary"
                                            (click)="this.commit()"
                                            (keyup.enter)="this.commit()"
                                        >
                                            {{ this.commitButtonText }}
                                        </button>
                                    }
                                </div>
                            }
                            @if (this.errorMessages) {
                                <div>
                                    @for (msg of this.errorMessages; track msg) {
                                        <div class="ngRed ngBold">
                                            {{ msg }}
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                }
            </div>
        }
    `,
    styles: [".editable-dp {display: flex; gap: 5px}", ".edit-icon {position: relative; vertical-align: middle;}"],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25EditableDateComponent extends S25EditableAbstract implements OnInit, OnChanges {
    @Input() dateFormat: string;
    @Input() minDate?: Date;
    @Input() maxDate?: Date;
    @Input() popoverOnBody?: boolean = false;
    @Input() inputId?: string;
    @Input() hasIcon = true;
    @Input() placeholder?: string = "Select a date";

    init: boolean = false;
    modelValue: any = {
        date: new Date(),
        showToday: true,
    };
    inputClass = "editable-input ngHoverOutline";

    getType = () => "date";

    constructor(
        public elementRef: ElementRef,
        private cd: ChangeDetectorRef,
        private zone: NgZone,
    ) {
        super(elementRef, cd, zone);
    }

    editDate() {
        this.editMode();
        this.zoneRef.run(() => {
            setTimeout(() => {
                DatepickerApi.open(this.elementRef.nativeElement);
            }, 1);
        });
    }

    setDate(dateVal: Date | string, skipRefresh?: boolean) {
        if (!dateVal && !this.allowEmpty) {
            this.modelValue.date = new Date();
            this.valChange.emit(this.modelValue.date);
            return;
        }
        try {
            this.modelValue.date = S25Util.date.parseDropTZ(dateVal) || (this.allowEmpty ? null : new Date());
        } catch (err: any) {
            this.modelValue.date = this.allowEmpty ? null : new Date();
        }

        !skipRefresh && DatepickerApi.refresh(this.elementRef.nativeElement, this.modelValue.date);
    }

    onUpdate = (inputVal: any) => {
        this.setDate(inputVal);
    };

    onCommit = (inputVal: any) => {
        this.setDate(inputVal);
    };

    onBlur = (inputVal: any) => {
        this.setDate(inputVal);
    };

    ngOnInit() {
        this.setDate(this.val, true);
        setTimeout(() => this.setDate(this.val, true));
        let p = jSith.when();
        if (!this.dateFormat) {
            p = UserprefService.getS25Dateformat().then((dateFormat) => {
                this.dateFormat = dateFormat;
            });
        }

        p.then(() => {
            super.ngOnInit();
            this.setInputClass();
            this.init = true;
            this.cd.detectChanges();
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.val) setTimeout(() => this.setDate(changes.val.currentValue));
    }

    setInputClass() {
        this.inputClass = this.editing ? undefined : "editable-input ngHoverOutline";
    }

    dateSubmit(candidateVal?: any, fromBlur?: boolean) {
        if (this.hasCommit) {
            this.commit();
        } else {
            const val = this.allowEmpty ? candidateVal : candidateVal || this.candidateVal;
            this.update(val, fromBlur);
            this.close();
        }
    }
}
