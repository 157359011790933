//@author: devin

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    NgZone,
    OnChanges,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { S25EditableAbstract } from "../s25.editable.abstract";

@TypeManagerDecorator("s25-ng-editable-richtext")
@Component({
    selector: "s25-ng-editable-richtext",
    template: `
        @if (this.readOnly) {
            <div [innerHTML]="this.val | safeHTML"></div>
        }

        @if (!this.readOnly) {
            <div>
                <div
                    class="s25-editable-shared-container c-editable-text editable editable-click"
                    (click)="this.editMode()"
                    (keyup.enter)="this.editMode()"
                    (keyup.space)="this.editMode()"
                    tabindex="0"
                >
                    @if (!this.editing) {
                        <div class="s25-editable s25-data" [innerHTML]="this.val | safeHTML"></div>
                    }
                </div>
                @if (this.editing) {
                    <div>
                        <s25-ng-rich-text-editor
                            [modelValue]="this.val"
                            [autoFocus]="true"
                            (onEditorContentChange)="this.update($event)"
                        ></s25-ng-rich-text-editor>
                        <div class="aw-button-group">
                            @if (this.hasCancelButton) {
                                <button
                                    class="aw-button aw-button--outline"
                                    (click)="this.cancel()"
                                    (keyup.enter)="this.cancel()"
                                >
                                    {{ this.cancelButtonText }}
                                </button>
                            }
                            @if (this.hasCommitButton) {
                                <button
                                    class="aw-button aw-button--primary"
                                    (click)="this.commit()"
                                    (keyup.enter)="this.commit()"
                                >
                                    {{ this.commitButtonText }}
                                </button>
                            }
                        </div>
                        @if (this.errorMessages) {
                            <div>
                                @for (msg of this.errorMessages; track msg) {
                                    <div class="ngRed ngBold">
                                        {{ msg }}
                                    </div>
                                }
                            </div>
                        }
                    </div>
                }
            </div>
        }
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25EditableRichtextComponent extends S25EditableAbstract implements OnInit, OnChanges {
    getType = () => "richtext";

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
        private zone: NgZone,
    ) {
        super(elementRef, cd, zone);
    }

    ngOnInit() {
        super.ngOnInit();
    }

    ngOnChanges() {
        this.valChange.emit(this.val);
    }
}
