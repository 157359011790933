import { DataAccess } from "../../dataaccess/data.access";
import { Timeout } from "../../decorators/timeout.decorator";
import { S25Util } from "../../util/s25-util";
import { Flavor } from "../../pojo/Util";
import { Proto } from "../../pojo/Proto";
import { Item } from "../../pojo/Item";
import CharBoolean = Proto.CharBoolean;
import NumericalBoolean = Proto.NumericalBoolean;
import URLString = Proto.URLString;

export class EmbeddedConfigService {
    @Timeout
    public static getConfigs() {
        return DataAccess.get<{ config: EmbeddedConfigListItem[] }>("/embedded/config.json").then((data) => {
            return (data && data.config) || [];
        });
    }

    @Timeout
    public static getConfig(configToken: string) {
        return DataAccess.get<{ config: EmbeddedConfig }>("/embedded/config.json?token=" + configToken).then((data) => {
            return data && data.config;
        });
    }

    @Timeout
    public static putConfig(configToken: string, payload: any) {
        payload = S25Util.deleteUndefDeep(payload);
        return DataAccess.put<{ root: { configToken: string } }>("/embedded/config.json?token=" + configToken, payload);
    }

    @Timeout
    public static deleteConfig(configToken: string): any {
        return DataAccess.delete("/embedded/config.json?token=" + configToken);
    }

    public static parseConfig(config: EmbeddedConfig) {
        const detailsList = !config.detailsList
            ? ({} as any)
            : {
                  ...config.detailsList,
                  preferOrgTitle: !!config.detailsList?.preferOrgTitle,
                  showLocationFormalName: !!config.detailsList?.showLocationFormalName,
                  timeInline: !!config.detailsList?.timeInline,
              };
        return {
            ...config,
            allowEventCreation: config.allowEventCreation === "T",
            allowSubscribe: config.allowSubscribe === "T",
            hasMultiQuery: !!config.hasMultiQuery,
            multiQueryDropdown: !!config.multiQueryDropdown,
            ...(!config.detailsList ? {} : { detailsList }),
        };
    }
}

export interface EmbeddedConfigListItem {
    configName: string;
    configToken: string;
    embeddedType: EmbeddedType;
    targetId: string;
}

export interface EmbeddedConfig {
    configName: string;
    configToken: string;
    embeddedType: EmbeddedType;
    allowEventCreation: CharBoolean;
    allowSubscribe: CharBoolean;
    comptype?: string;
    css: string;
    formMode: boolean;
    hasMultiQuery: NumericalBoolean;
    instId: string;
    multiQueryDropdown: NumericalBoolean;
    sessionId: string;
    targetId: string;
    url: URLString;
    itemId?: number;
    itemName?: string;
    itemTypeId?: Item.Ids;
    queryId?: number;
    queryName?: string;
    queryList?: { itemId: number; itemName: string }[];
    eventFormId?: number;
    detailsList?: {
        elementDateFormat: Flavor<string, "date format">;
        endDateFormat: Flavor<string, "date format">;
        groupNameFormat: string;
        grouping: "day" | "week" | "month";
        preferOrgTitle: NumericalBoolean;
        showLocationFormalName: NumericalBoolean;
        startDateFormat: Flavor<string, "date format">;
        timeInline: NumericalBoolean;
        element: {
            display: string;
            name: string;
            sortOrder: number;
        }[];
    };
}

export type EmbeddedType =
    | "availability"
    | "availability-weekly"
    | "availability-weekly-mult-loc"
    | "event-form"
    | "calendar"
    | "details-list"
    | "express";

export type EmbeddedCompType = "availability" | "availability_daily";
