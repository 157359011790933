//@author: sean
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { S25EditablePropertyComponent } from "./s25.editable.property.component";
import { S25MultiselectModule } from "../s25-multiselect/s25-multiselect.module";

@NgModule({
    declarations: [S25EditablePropertyComponent],
    imports: [CommonModule, FormsModule, S25MultiselectModule],
    providers: [S25EditablePropertyComponent],
    exports: [S25EditablePropertyComponent],
})
export class S25EditablePropertyModule {}
