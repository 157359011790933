import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    NgZone,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { S25EditableAbstract } from "../s25.editable.abstract";
import { TypeManagerDecorator } from "../../../main/type.map.service";

@TypeManagerDecorator("s25-ng-editable-percentage")
@Component({
    selector: "s25-ng-editable-percentage",
    template: `
        @if (this.readOnly) {
            <span>{{ this.val | number: "1.1-3" }}%</span>
        }

        @if (!this.readOnly) {
            <div>
                @if (!this.editing) {
                    <div
                        class="s25-editable-shared-container c-editable-text editable editable-click"
                        (click)="this.editMode()"
                        (keyup.enter)="this.editMode()"
                        (keyup.space)="this.editMode()"
                        [attr.role]="'button'"
                        [attr.aria-label]="'Edit Percentage'"
                        [tabIndex]="0"
                    >
                        <div class="s25-editable s25-data">{{ this.val | number: "1.1-3" }}%</div>
                    </div>
                }
                @if (this.editing) {
                    <div>
                        <input
                            class="firstFocusEditable"
                            type="number"
                            [disabled]="this.disabled"
                            [min]="this.min"
                            [max]="this.max"
                            step="1"
                            [ngModel]="this.candidateVal"
                            (ngModelChange)="this.update($event)"
                            (blur)="this.onInputBlur()"
                            (keyup.enter)="this.submit()"
                        />
                        @if (this.hasCancelButton) {
                            <button
                                class="aw-button aw-button--outline"
                                (click)="this.cancel()"
                                (keyup.enter)="this.cancel()"
                            >
                                {{ this.cancelButtonText }}
                            </button>
                        }
                        @if (this.hasCommitButton) {
                            <button
                                class="aw-button aw-button--primary"
                                (click)="this.commit()"
                                (keyup.enter)="this.commit()"
                            >
                                {{ this.commitButtonText }}
                            </button>
                        }
                        @if (this.errorMessages) {
                            <div>
                                @for (msg of this.errorMessages; track msg) {
                                    <div class="ngRed ngBold">
                                        {{ msg }}
                                    </div>
                                }
                            </div>
                        }
                    </div>
                }
            </div>
        }
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.Default,
})
export class S25EditablePercentageComponent extends S25EditableAbstract implements OnInit {
    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
        private zone: NgZone,
    ) {
        super(elementRef, cd, zone);
    }

    getType = () => "percentage";

    ngOnInit() {
        super.ngOnInit();
    }
}
